/* Selected Tags */
.vs__selected {
    display: flex;
    align-items: center;
    background-color: var(--vs-selected-bg);
    border: var(--vs-selected-border-width) var(--vs-selected-border-style)
        var(--vs-selected-border-color);
    border-radius: var(--vs-border-radius);
    color: var(--vs-selected-color);
    line-height: var(--vs-line-height);
    margin: 4px 2px 0px 2px;
    padding: 0 0.25em;
    z-index: 0;
}

.vs__deselect {
    display: inline-flex;
    appearance: none;
    margin-left: 4px;
    padding: 0;
    border: 0;
    cursor: pointer;
    background: none;
    fill: var(--vs-controls-color);
    text-shadow: var(--vs-controls--deselect-text-shadow);
}

/* States */

.vs--single {
    .vs__selected {
        background-color: transparent;
        border-color: transparent;
    }
    &.vs--open .vs__selected,
    &.vs--loading .vs__selected {
        position: absolute;
        opacity: 0.4;
    }
    &.vs--searching .vs__selected {
        display: none;
    }
}
