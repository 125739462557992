:root {
    --vs-transition-timing-function: cubic-bezier(1, 0.5, 0.8, 1);
    --vs-transition-duration: 0.15s;
}

/* KeyFrames */
@-webkit-keyframes vSelectSpinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes vSelectSpinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Dropdown Default Transition */
.vs__fade-enter-active,
.vs__fade-leave-active {
    pointer-events: none;
    transition: opacity var(--vs-transition-duration)
        var(--vs-transition-timing-function);
}
.vs__fade-enter,
.vs__fade-leave-to {
    opacity: 0;
}
